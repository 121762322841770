<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn icon small @click="close" color="primary">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3 class="ml-2">Rejected</h3>
        </v-card-title>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-0"
                :mobile-breakpoint="0"
                disable-pagination
                hide-default-footer
            >
                <template v-slot:[`item.date`]="{ item }">
                    <p class="my-0">{{ formatDate(item.createdOn) }}</p>
                </template>
                <template v-slot:[`item.user`]="{ item }">
                    <p class="my-0" v-if="item.userName">{{ item.userName }}</p>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import moment from 'moment'
import API from '@/services/api'

export default {
    name: 'RejectionWOTable',
    props: {
        items: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data: () => ({
        loading: false,
        headers: [
            {
                text: 'DATE',
                value: 'date',
                align: 'center',
                sortable: false,
            },
            {
                text: 'USER',
                value: 'user',
                align: 'left',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
        ],
    }),
    async mounted() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.items.forEach(item => {
                const user = users.find(user => user.id == item.user)
                if (user) {
                    item.userName = user.name
                }
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        formatDate(date) {
            return moment
                .unix(date.seconds || date._seconds)
                .format('YYYY-MM-DD')
        },
        close() {
            this.$emit('closeDialog')
        },
    },
}
</script>

<style></style>
